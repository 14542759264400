class FrontTemplates {

    addToCartSuccess(urlToCart, count, name, arrAttributes)
    {
        return "" +
            "<div class='modal-content__add-to-cart'>" +
            "<div class='modal-content__add-to-cart--content'>" +
            "<h4>" + name + "</h4>" +
            this.addToCartSuccess__attributesList(arrAttributes) +
            "</div>" +
            "<div class='modal-content__add-to-cart--buttons'>" +
            "<a href='javascript:void(0);' class='base-button-transparent " + modalWindow.elements.customCloseButton + "'>" + translate.getTranslate('ADD_TO_CART_CLOSE_MODAL') + "</a>" +
            "<a href='" + urlToCart + "' class='base-button-action-colored'>" + translate.getTranslate('ADD_TO_CART_BUY_IT') + "</a>" +
            "" +
            "</div>" +
            "</div>"
        ;
    }

    addToCartSuccess__attributesList(arrAttributes)
    {
        if(_.isEmpty(arrAttributes)){
            return '';
        }
        let
            out = [],
            data = [];
        out.push('<div class="modal-content__add-to-cart--attributes">');
        _.each(arrAttributes, function(value, name){
            data.push(value);
        });
        out.push(data.join(', '));
        out.push('</div>');

        // out.push('<div><dl>');
        // _.each(arrAttributes, function(value, name){
        //     out.push('<dt>' + name + '</dt><dd>' + value + '</dd>');
        // });
        // out.push('</dl></div>');

        return out.join('');
    }

    constructor() {}

}